<template>
  <v-app>
    <v-main>
      <router-view v-bind="$route.meta" />
    </v-main>
  </v-app>
</template>

<script>
export default {
  computed: {
    xsOnly() {
      return this.$vuetify.breakpoint.xsOnly;
    },
  },
  watch: {
    $route() {
      this.updateDocumentTitle();
    },
    name() {
      this.updateDocumentTitle();
    },
  },
  created() {
    this.updateDocumentTitle();
  },
};
</script>
